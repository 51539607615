// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import  {getAuth} from "firebase/auth";
 
const firebaseConfig = {
  apiKey: "AIzaSyAwijO3yn_TGipQYsMu7XWB-SN5D8N2iJY",
  authDomain: "eternal-remembrance-f030d.firebaseapp.com",
  projectId: "eternal-remembrance-f030d",
  storageBucket: "eternal-remembrance-f030d.appspot.com",
  messagingSenderId: "818145939387",
  appId: "1:818145939387:web:cb5a8393fce21938e6be9e",
  measurementId: "G-WWK16KDJLQ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {app, auth};



 