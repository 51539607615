import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase'; // Import the initialized auth from firebase.js
import Header from '../components/Header';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User created:', user);
      // You can add additional logic here, such as saving the username in a Firestore database.
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <style>
        {`
          .shadow-div {
            background-color: white;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            padding: 16px;
          }
        `}
      </style>
      <Header />
      <div className="flex items-center justify-center shadow-div">
        <div className="bg-white p-8 rounded-lg shadow-div w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center mb-6">Sign Up</h1>
          <form onSubmit={handleSubmit}>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700 mb-2">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full p-3 bg-blue-900 text-white rounded-lg hover:bg-blue-700"
            >
              Sign Up
            </button>
          </form>
          <p className="text-center mt-4">
            Do you have an account? 
            <Link to="/login" className="text-blue-600 hover:underline"> Log in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
